@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,500&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    background: #1e1e2f;
    height: fit-content;
    
}