.mintcard {

    padding-left: 29px;
    padding-right: 29px;
    padding-top: 140px;
    padding-bottom: 80px;
  
    border: 3px solid rgba(255, 255, 255, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    background-repeat: no-repeat;
    background-size: 490px 650px;
    backdrop-filter: blur(86px) saturate(122%);
    -webkit-backdrop-filter: blur(16px) saturate(122%);
    background-color: #156c6349;
    border-radius: 12px;
    border: 6px solid rgb(255, 255, 255);

}

.test{
    width: 500px;
    height: 600px;
}


.mobalert {
    display: none;
}

.boxes {
    color: white !important;
    display: flex;
    justify-content: center;
    padding: 15px;
    margin-top: 260px;
    border-radius: 16px;
    box-shadow: 0 4px 30px #3d3d3d35;
    backdrop-filter: blur(4.2px);
    -webkit-backdrop-filter: blur(4.2px);
    border: 1px solid rgb(92, 92, 92);
}




@media (max-width: 767px) {

    .mobalert {
        color: rgb(251, 63, 63) !important;
        width: 300px;
        padding-bottom: 15px;
        text-align: center;
        padding-left: 40px;
        
    }

    .mintcard {

        padding-left: 29px;
        padding-right: 29px;
        padding-top: 140px;
        padding-bottom: 80px;
      
        border: 3px solid rgba(255, 255, 255, 0);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 600;
        background-repeat: no-repeat;
        background-size: 490px 650px;
        backdrop-filter: blur(86px) saturate(122%);
        -webkit-backdrop-filter: blur(16px) saturate(122%);
        background-color: #156c6349;
        border-radius: 12px;
        border: 6px solid rgb(255, 255, 255);
    
    }
    

}

.progressBar {
    appearance: none;
    height: 5px;
    border-radius: 100px;
    width: 200px;
  }
  
  /* Chrome and Safari */
  .progressBar::-webkit-progress-bar {
    background: black;
    border-radius: 100px;
  }
  
  .progressBar::-webkit-progress-value {
    background-color: #d85f5d;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  
  /* Firefox */
  .progressBar::-moz-progress-bar {
    background-color: #d85f5d;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  