@font-face {
  font-family: 'MyFont';
  src: local('Big'), url(../assets/fonts/Big.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.logos {
  font-size: 40px;
  font-weight: 800;
}
.link {
  
    background-color: #ffffff;
 
    color: #231f20;
    transition: background-color .4s linear;
    margin-left: 10px;
  }
  
  .activ1 {
    
  
  
    background-color: #1da0f2;
    transition: background-color .5s linear;
  }
  
  @media (max-width: 768px) {
    
    .w-screen {
      flex-direction: column;
      
      
    }
  
    .w-96 {
      width: 100%;
      justify-content: space-evenly;
    }

    .mush {
      width: 80px !important;
      /* animation: shiver 1s infinite;
      transform-origin: center;
      filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.8)); */
    }

    
  }
  
  
  .mush {
    width: 100px;
  
    /* animation: shiver 1s infinite;
    transform-origin: center;
    filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.8)); */
  }

  .titleLog{
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.1;
  }
  
  @keyframes shiver {
    0% {
      transform: translateY(0) rotate(0) scale(1);
      opacity: 1;
    }
    25% {
      transform: translateY(-2px) rotate(-2deg) scale(0.98);
      opacity: 0.6;
    }
    50% {
      transform: translateY(2px) rotate(2deg) scale(1.02);
      opacity: 0.4;
    }
    75% {
      transform: translateY(-2px) rotate(-2deg) scale(0.98);
      opacity: 0.6;
    }
    100% {
      transform: translateY(0) rotate(0) scale(1);
      opacity: 1;
    }
  }
  