@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,500&display=swap');

.abt {
  width: 70%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
 padding-left: 50px;
 padding-right: 50px;
}


.text-contain {
 
  flex: 1;
    
    padding: 1rem;
    text-align: center;
    letter-spacing: 0.08em;
    backdrop-filter: blur(36px) saturate(122%);
    -webkit-backdrop-filter: blur(16px) saturate(122%);
    background-color: #1dac9e;
    background-color: #156c6349;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    
  }

  .text-contain p{
    color: #ffffff;
    font-size: 15px;
    margin-top: 10px;
    letter-spacing: 0.08em;
    font-weight: 400;
  }
  .text-contain h1{
    font-family: 'Jap';
    font-size: 80px;
    color: #ffffff;
    font-weight: bolder;
  }
  
  @media (max-width: 767px) {

    .abt {
      width: 90%;
      padding-left: 0px;
 padding-right: 0px;
    }
    .text-contain {
      width: 100%;
      margin-top: 0px;
    }

    .text-contain h1 {
      font-size: 40px;
    }


  }
  
  