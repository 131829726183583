.card {
    /* From https://css.glass */
    width: 250px;
    height: 250px;
    border-radius: 0px;
    
    
}

@media (max-width: 767px) {

    .card {
        width: 250px;
        height: 250px;
        border-radius: 0px;
    }
}