@font-face {
  font-family: 'Jap';
  src: url('../assets/fonts/Japanese.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
} 
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.containe1 {
  
  width: 70%;
  
  }



  .right-col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    text-align: center;
    letter-spacing: 0.08em;
    backdrop-filter: blur(36px) saturate(122%);
    -webkit-backdrop-filter: blur(16px) saturate(122%);
    background-color: #1dac9e;
    background-color: #156c6349;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    
  }
  
  .right-col h4 {
        
        color: #ffffff;
        font-weight: bolder;
        
  }

  .right-col h1 {
    font-family: 'Jap';
    font-size: 80px;
    color: #ffffff;
    font-weight: bolder;
    
}
  /* Adjust padding for screens with a maximum width of 1200px */
@media (max-width: 4000px) {
  .right-col h4 {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 50px;
    color: #ffffff !important;
    font-weight: bolder;
    
}
}
  /* Adjust padding for screens with a maximum width of 1200px */
@media (max-width: 2000px) {
  .right-col h4 {
    color: #000000;
    font-weight: bolder;
    
}
}



  .right-col button{

  }



  @media (max-width: 767px) {
    
    .containe1 {
      flex-direction: column;
    width: 100%;
    font-family: DelightCoffee;
    letter-spacing: 0.08em;
    font-weight: 600;
    padding-left: 5px;
    padding-right: 5px;
    
    }

    .left-col img{

    width: 295px;
    height: 290px;
    
    }

    .right-col button{
      margin-left: 0%;
    }

    .left-col{
      width: 360px;
    height: 360px;
    justify-content: center;
    padding: 10px;
    }
    .animate-charcter{
      font-size: 80px;
    }


    .right-col h4 {
      font-size: 14px;
      margin-top: 0px;
          padding-left: 3%;
          padding-right: 6%;
          color: #000000;
          font-weight: bolder;
    }

   

    .titleLogo{
      color: #ff6767;
      font-family: 'Poppins', sans-serif;
      font-size: 30px;
      font-weight: 600;
      line-height: 1.1;
    }

    .right-col {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding:1.4rem;
      
      letter-spacing: 0.08em;
      font-family: 'Poppins', sans-serif;
      text-align: center;
    }
    
    
  }

 