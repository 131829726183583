
.HomeBg {
    

    
    min-height: 100vh;
     height: fit-content;
     overflow: hidden;
     
     background-image: url('../assets/background/bgx.jpg');
     /* background-color: #00000000; */
    background-size: cover;
    
     
}

.ogBG {
    
    background-size: cover;
    min-height: 100vh;
    height: fit-content;

}

@media (max-width: 767px) {
    .HomeBg {
    

    
        min-height: 100vh;
         height: fit-content;
         overflow: hidden;
         
        
         /* background-color: #00000000; */
        background-size: cover;
         
    }
    
    .ogBG {
        
      
        background-size: cover;
        min-height: 100vh;
        height: fit-content;
    
    }

}