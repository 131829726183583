
  #rangeValue {
    position: relative;
    display: block;
    text-align: center;
    font-size: 4em;
    color: #ffffff;
    font-weight: 800;
    cursor: default;
  }

  .range {
    width: 300px;
    height: 15px;
    margin-top: 10px;
    -webkit-appearance: none;
    background: rgb(133, 133, 133)49;
    outline: none;
    border-radius: 15px;
    cursor: pointer;
    overflow: hidden;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);
  }
  
  .range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    border: 4px solid #333;
    box-shadow: -407px 0 0 400px #32ffeb;
  }
  