.grid-container {
    display: flex;
   padding: 10px;
   padding-left: 120px;
  }
  
  .cards {
    width: calc(40% - 10px);
    text-align: center;
    /* background-color: #000000; */
    color: rgb(255, 255, 255);
    /* background: #2b2b2b86; */
    background-image: url(../assets/background/frame2.png);
    background-repeat: no-repeat;
    background-size: contain;
    /* box-shadow: 0 4px 30px #0000004e;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgb(255, 255, 255); */
    
  }
  
  .cards img {
    padding: 80px;
    width: 900px;
    justify-content: center;
  }
  .cards p {
    margin: 15px;
    letter-spacing: 0.05em;
    font-weight: 900;
    
  }
  @media only screen and (max-width: 600px) {

    .grid-container {
      display: flex;
      flex-direction: column;
      padding-left: 0% !important;
      justify-content: center;
      align-items: center;
    }
    .crd {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .cards {
      width: calc(80% - 10px);
      text-align: center;
      /* background-color: #000000; */
      color: rgb(255, 255, 255);
      margin-top: 20px;
      /* background: #2b2b2b86; */
      background-image: url(../assets/background/frame2.png);
      background-repeat: no-repeat;
      background-size: contain;
      /* box-shadow: 0 4px 30px #0000004e;
      backdrop-filter: blur(0px);
      -webkit-backdrop-filter: blur(2px);
      border: 1px solid rgb(255, 255, 255); */
      
    }

    h4.cyber-h {
      font-size: 1.5rem;
      margin-bottom: 1.2rem 0;
      width: 70%;
  }

    .teamdetails {

      width: calc(80% - 20px);
      margin-left: 30px;
    }

  
  }
  
  .titleGrid {
    font-size: 20px;
    font-weight: 900;
    
  }


  .teamdetails {

    width: calc(80% - 20px);

  }

  h4.cyber-h {
    font-size: 1.5rem;
    margin-bottom: 1.2rem 0;
    width: 70%;
}